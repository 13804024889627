html,
body,
#root,
.app {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #18181b;
  color: #eee;
}

@-webkit-keyframes example {
  from {
    box-shadow: 0 0 100px rgba(#9147ff, .25);
  }
  to {
    box-shadow: 0 0 200px rgba(#9147ff, .33);
  }
}

/* Standard syntax */
@keyframes halo {
  from {
    box-shadow: 0 0 25px rgba(#9147ff, .25);
  }
  to {
    box-shadow: 0 0 150px rgba(#9147ff, .5);
  }
}

.app {
  display: flex;
  align-items: center;
  justify-content: center;
}

.twitch-wrapper {
  background: #18181b;
  animation-name: halo;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  border: 1px solid rgba(#9147ff, .45);
  border-bottom: 1px solid rgba(#9147ff, .65);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: flex;
  overflow: hidden;
}